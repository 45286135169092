::-ms-input-placeholder {
	color: #808080 !important;
	opacity: 0.5 !important;
}

body {
	// font-family: "Inter-Medium";
	background-color: $theme-bg;
}

a,
a:hover {
	color: $font-color1;
	text-decoration: none;
}

ul {
	padding-left: 0px;
	margin: 0;
}

li {
	list-style: none;
}

h3,
p {
	// font-family: "Inter-Regular";
}

.product-name {
	//   font-family: 'proxima-nova';
}

h6 {
	// font-family: 'proxima-nova';
}

h4 {

	// font-family: "Inter-Medium";
	&.bold {
		// font-family: "Inter-ExtraBold";
	}

	&.sm-bold {
		// font-family: "proxima-nova";
		font-size: 14px !important;
	}
}

.pb-50 {
	padding-bottom: 50px;
}

ul {
	&.two-column {
		columns: 2;
		-webkit-columns: 2;
		-moz-columns: 2;

		li {
			font-size: 14px;

			.list-label {
				color: $textgray;
				margin-right: 15px;
				min-width: 105px;
			}

			.list-value {
				color: $primary-color;
			}
		}

	}

	&.three-column {
		columns: 3;
		-webkit-columns: 3;
		-moz-columns: 3;

		li {
			font-size: 14px;

			.list-label {
				color: $textgray;
				min-width: 105px;
			}

			.list-value {
				color: $primary-color;
			}
		}

	}
}

/** Title **/
.content-title {
	text-align: center;
	padding: 60px 0px 30px 0px;

	.title-box {
		.title {
			display: inline-block;
			position: relative;
			// font-family: 'proxima-nova';
			margin-bottom: 5px;
			font-size: 1.8rem !important;
			color: #FBCB0C;
			// span {
			// 	background-color: $theme-bg;
			// }
			// &:after {
			// 	content:"";
			// 	width:100%;
			// 	position: absolute;
			// 	bottom:2px;
			// 	height:2px;
			// 	background:#000;
			// 	left:0;
			// 	z-index: -1;
			// }

		}

		.sub-title {
			// font-family: 'Inter-Regular';
			color: #a7a7a7;
			font-size: 1.3rem !important;
		}
	}
}


.row-fluid {
	display: flex;
	flex-flow: wrap;
}

.sub-heading {
	text-transform: uppercase;
	font-size: 17px;
	// font-family: 'Inter-Medium';
	margin-bottom: 20px;
	cursor: pointer;
}


/**Product box one **/
.product-box-one {
	.product-detail {
		background-color: #fff;
		padding: 10px;
		position: relative;
		border: 2px solid #eceaea;

		.product-name {
			padding-right: 30px;
		}

		.product-option {
			.wishlist {
				.add-to-wishlist {
					position: absolute;
					top: 0px;
					right: 0px;
					font-size: 22px;
				}
			}
		}
	}
}

/**product**/

.product-name {
	font-size: 18px;
}

h6 {
	&.product-name {
		font-size: 19px;
	}
}

/***Listing**/
.collection-wrapper {
	.collection-content {
		background-color: #fff;
	}

	padding-top: 30px;
	padding-bottom: 30px;

	.collection-top {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.collection-filter {
		background-color: #fff;

		.filter-top {
			border-bottom: 1px solid #dddddd;

			.filter-clear {
				padding: 10px;
				display: flex;
				justify-content: space-between;
			}
		}

		.layerd-navigation {
			padding: 10px;
			// font-family: "Inter-Regular";
			border-bottom: 1px solid #dddddd;

			.collection-collapse-block {
				margin-bottom: 10px;

				.collection-collapse-block-content {
					padding-left: 30px;

					.options {
						margin: 8px 0px 8px 0px;

						input {
							margin-right: 10px;
						}

						label {
							margin-bottom: 0px;
							font-size: 15px;

							.count {
								color: #afabab;
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}

	.col-grid-box {
		width: 100%;
		background: #fff;
		padding: 0px 10px 0px 10px;
	}

	.sortby {
		.input-group-text {
			background-color: #fff;
			border-radius: 0;
			border-right: 0;
		}

		.sortby-options {
			border-left: 0px;
			border-radius: 0;
		}
	}
}

/** Product Details**/

.product-details-wrapper {
	.details-top {
		padding: 15px 0px 15px 0px;
	}

	.slider-nav {
		max-width: 400px;
		min-width: 300px;
		position: absolute;
		bottom: 50px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 9;

		.owl-thumb {
			img {
				opacity: 0.5;
			}

			background-color: #000;
			cursor: pointer;

			&:hover {
				background-color: transparent;
				border: 2px solid #f7f1f1;

				img {
					opacity: 1;
				}
			}

			&.active {

				border: 2px solid #f7f1f1;
				background-color: transparent;

				img {
					opacity: 1;
				}
			}
		}
	}

	.product-all-details {
		.product-name {
			font-size: 30px;
		}
	}

	.details-wrapper {
		.product-all-details {
			background-color: #fff;
			padding: 0px 15px 15px 15px;

			.price {
				font-size: 24px;
			}

			.row {
				padding: 15px;

				.col-left {
					flex: 1;
					padding-right: 15px;
				}

				.col-right {
					min-width: 250px;

					.add-to-cart {
						margin-bottom: 30px;
					}

					.delivery-option {
						margin-top: 30px;

						h6 {
							.fa {
								font-size: 22px;
								-webkit-transform: scaleX(-1);
								transform: scaleX(-1);
							}
						}

						.input-group {
							input {
								border-right: 0px;
							}

							.input-group-text {
								background-color: #fff;
								border-left: 0;
								color: $primary-color;
							}
						}

					}

				}
			}

			.description {
				padding-bottom: 15px;
			}

			.product-addtional-details {
				position: relative;
				padding-top: 15px;

				&::before {
					content: "";
					position: absolute;
					width: calc(100% - 198px);
					height: 2px;
					background: #707070;
					opacity: 0.2;
					box-shadow: 0;
					top: -10px;
				}
			}
		}

	}

}

.ng5-slider {
	.ng5-slider-pointer {
		cursor: pointer;
		width: 20px !important;
		height: 20px !important;
		top: -8px !important;
		background-color: #fff !important;
		z-index: 3;
		border-radius: 16px !important;
		box-shadow: 2px 3px 9px 2px rgba(0, 0, 0, 0.56);
		-webkit-box-shadow: 2px 3px 9px 2px rgba(0, 0, 0, 0.56);
		-moz-box-shadow: 2px 3px 9px 2px rgba(0, 0, 0, 0.56);
	}

	.ng5-slider-span:focus {
		outline: 0;
	}

	.ng5-slider-pointer:after {
		display: none !important;
	}

	.ng5-slider-selection {
		z-index: 2;
		background: $primary-color !important;
		border-radius: 2px;
	}
}

/** Pages **/

.homepage-banner-slider {
	.owl-theme {
		.owl-dots {
			position: absolute;
			bottom: 15px;
			left: 50%;
		}

		.owl-next,
		.owl-prev {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background-color: $theme-bg !important;
			border: 0px !important;

			.fa {
				font-size: 30px;
			}
		}

		.owl-next {
			right: 0;
			background: $theme-bg;
			width: 20px;
		}

		.owl-prev {
			left: 0;
			background: $theme-bg;
		}
	}
}

.rounder-corner-nav {
	&.top-nav-right {
		.owl-theme {
			.owl-nav {
				position: absolute;
				top: -70px;
				right: 0;
			}
		}
	}

	&.top-nav-bg-white {
		.owl-theme {
			.owl-nav {
				margin-top: 0px;

				.owl-prev,
				.owl-next {
					background: #fff;
					border: 0px;

					.fa {
						color: #000;

					}
				}
			}
		}
	}

	.owl-theme {
		.owl-nav {
			margin-top: 0px;

			.owl-prev,
			.owl-next {
				border-radius: 20px;
				width: 40px;
				height: 40px;
				background: transparent;
				border: 2px solid #b7b6b5;

				.fa {
					color: #b7b6b5;
					font-size: 22px;
				}
			}
		}
	}
}

.rotate-dot {
	.owl-theme {
		.owl-dots {

			transform: translateX(-50%);

			[class*="owl-"] {
				width: 25px;
				height: 25px;
				transform: rotate(130deg);
				margin: 0px 6px;
				border: 1px solid transparent;

				&.active {
					border: 1px solid $primary-color;

					span {
						background-color: $primary-color;
					}
				}

				span {
					width: 12px;
					height: 12px;
					border-radius: 0;
					margin: 5px 5px;
				}
			}
		}
	}
}


.featured-collections {
	.tab-image {
		min-height: 300px;
		text-align: center;
		position: relative;
		margin-bottom: 30px;
		cursor: pointer;
		overflow: hidden;
		position: relative;

		.background-image {
			height: 100%;
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			-webkit-transition: all .5s;
			-moz-transition: all .5s;
			-o-transition: all .5s;
			transition: all .5s;
			min-height: 300px;

			&:hover {
				transform: scale(1.2);
			}
		}

		.bg-content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #fff;
			font-size: 25px;

			p {
				font-size: 15px;

			}

		}

	}
}


.best-products {
	background: #fff;
	padding-top: 50px;
	padding-bottom: 30px;

	.title {
		text-align: center;
		padding-bottom: 20px;

		h2 {
			// font-family: 'proxima-nova';
		}
	}

	.column {

		-webkit-flex: 1;
		/* Safari */
		-ms-flex: 1;
		/* IE 10 */
		flex: 1;
		/* Standard syntax */

		&.grid-image {
			-webkit-flex: 2;
			/* Safari */
			-ms-flex: 2;
			/* IE 10 */
			flex: 2;
			/* Standard syntax */
		}

		&.single-image {
			border: 1px solid #c5c5c5;
			margin-bottom: 20px;
			text-align: center;

			.trers-single-image {
				img {
					min-width: 300px;
				}
			}

			h4 {
				font-size: 20px;
				text-transform: uppercase;
			}
		}

		.tyers-grid {
			display: flex;
			flex-flow: wrap;
			margin: 0;

			li {
				width: calc(50% - 20px);
				border: 1px solid #c5c5c5;
				margin: 0px 20px 20px 0px;
				padding: 10px;
				display: flex;
				align-items: center;

				.trers-grid-image {
					img {
						padding: 10px;
					}
				}

				a {
					cursor: pointer;
					display: block;
				}

				&:hover {
					border-color: var(--theme-deafult);
				}

				h4 {
					font-size: 20px;
					text-transform: uppercase;
				}

			}
		}


	}

}

.mobile-cart {
	display: none !important;
}

.mobile-only {
	display: none !important;
}

.home-featured-collections-title {
	font-size: 1.4rem;
	text-transform: uppercase;
	font-weight: bold;
	color: black;
	text-align: center;

}

.home-featured-collections {
	margin-left: 100px;
}

.deera-details-btn {
	width: 40% !important;
	margin-left: 30px !important;
}

.viewdetails-btn {
	padding: 5px 20px !important;
	border: none !important;
	font-size: 16px !important;
}

.deera-h4-align {
	display: flex;
	justify-content: center;
	margin-bottom: 35px;
}

@media screen and (max-width:600px) {
	.align-left-com {
		margin-left: -3% !important;
	}


	.deera-h4-align {
		display: flex;
		justify-content: center;
		margin-bottom: 35px;
		padding-top: 32px;
	}

	.deera-atc {
		font-size: small;
		font-weight: bold;
		width: 45%;
		margin-top: 15% !important;
		background-color: transparent !important;
		border: 3px solid #3c707c !important;
		color: #091214 !important;
		background-color: white;
	}

	body .align_price {
		display: unset !important;
	}

	.deera_align_h4 {
		padding-top: 7%;
	}

	.featured_align {
		display: grid;
		grid-template-columns: repeat(1, 1fr) !important;
	}

	.featured_img {
		cursor: pointer;
		width: 50% !important;
		border: none !important;
	}

	.home-featured-collections {
		margin-left: 0px !important;
		width: 120%;
	}

	.btn-width-48 {
		width: 48% !important;
	}

	.deera-m-b-5 {
		margin-bottom: -5%;
	}

	.deera-hide {
		display: none !important;
	}

	.deera-align-checkoutcart {
		left: 25%;
		position: relative;
	}

	.deera-details-btn {
		width: 40% !important;
		margin-left: 0px !important;
	}

	.viewdetails-btn {
		display: none !important;
	}

	.featured-collections {
		.tab-image {
			min-height: 104px !important;
			height: 100px;
			text-align: center;
			position: relative;
			margin-bottom: 30px;
			cursor: pointer;
			overflow: hidden;
			position: relative;

			.background-image {
				position: relative !important;
				min-height: 100px;
				width: 120%;
				margin-left: -10%;

				background-repeat: no-repeat;
				-webkit-transition: all .5s;
				-moz-transition: all .5s;
				-o-transition: all .5s;
				transition: all .5s;

				&:hover {
					transform: scale(1.2);
				}
			}

			.bg-content {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				color: #fff;
				font-size: 25px;

				p {
					font-size: 15px;
				}

			}

		}
	}

	.rounder-corner-nav {
		&.top-nav-right {
			.owl-theme {
				.owl-nav {
					position: absolute;
					top: -70px;
					right: 0;
				}
			}
		}

		&.top-nav-bg-white {
			.owl-theme {
				background-color: transparent !important;

				.owl-nav {
					margin-top: 0px;
					background-color: transparent !important;

					.owl-prev,
					.owl-next {
						background: transparent !important;
						border: 0px;

						.fa {
							color: #000;

						}
					}
				}
			}
		}

		.owl-theme {
			.owl-nav {
				margin-top: 0px;
				z-index: -100;
				position: absolute;
				top: 45%;
				display: flex;
				width: 100%;
				text-align: center;
				justify-content: center;
				top: 35%;

				.owl-next {
					position: relative;
					margin-left: 85%;
					width: 40px;
					height: 40px;
					background: transparent;
					border: none !important;
					background-color: transparent !important;

					.fa {
						color: black;
						font-size: 40px;
					}
				}

				.owl-prev {
					position: relative;
					margin-left: -6%;
					width: 40px;
					height: 40px;
					background: transparent;
					border: none !important;
					background-color: transparent !important;

					.fa {
						color: black;
						font-size: 40px;
					}
				}
			}
		}
	}

	.mobile-cart {
		display: flex !important;
		float: right;
	}

	.desktop-carousel {
		display: none !important;
	}

	.desktop-only {
		display: none !important;
	}

	.mobile-only {
		display: block !important;
	}

	.mobile-options {
		position: absolute !important;
		margin-top: -62px !important;
		margin: 0;
		margin-left: 160px;
		left: 0;
		border: none !important;
	}

	.mob-eye {
		margin-left: 40px
	}

	.mob-second {
		margin-top: -31px
	}

	.forgot-link {
		margin-top: -26px !important
	}

	.forgot-link-checkout {
		margin-top: -45px !important
	}

	.back-btn {
		width: 35% !important;
		margin-left: 65%;
	}

	.mob-logo {
		transform: scale(.9);
		top: 5px !important;
		left: 25%;
		position: fixed;
		z-index: 3;
		width: 50%;
		object-fit: contain;
	}

	.home-featured-collections-title {
		font-size: 1rem;
	}
}

@media screen and (max-width:391px) {
	.home-featured-collections-title {
		font-size: 0.9rem;
	}

}

@media screen and (max-width:360px) {
	.home-featured-collections-title {
		font-size: 0.8rem;
	}

}

@media screen and (min-width:601px) {
	.logo-image {
		margin-left: 3%
	}

}

.card-body img {
	max-width: 100%;
	max-height: 100%;
	/* Additional styling if needed */
}

.deera-newcard {
	height: 370px !important;
}

.footer_bgcolor {
	background-color: #0c377b;

}

.footer-color {
	color: white;
}

.featured_align {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.featured_img {
	cursor: pointer;
	width: 80%;
	border: none !important;
}

.deera_align_h4 {
	padding-top: 3%;
}

.my-pagination .ngx-pagination .current {
	background: #0c377b !important;
	margin-top: 14px;
}

body .deera_toggle {
	position: absolute !important;
	top: 10px !important;

}

body .autocomplete-items2 {
	position: absolute;
	border: 1px solid #d4d4d4;
	border-bottom: 2px solid #ddd;
	// margin-left: 15%;
	margin-left: 0px;
	width: 100% !important;
	border-top: none;
	z-index: 99;
	font-size: 14px !important;
	font-weight: 600 !important;
	/*position the autocomplete items to be the same width as the container:*/
	top: 98%;
	left: 0;
	right: 0;
	max-height: 400px;
	overflow: auto;

}

.custom-scrollbar::-webkit-scrollbar {
	width: 8px;
	/* Adjust the width as needed */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background: #07357b;
	/* Color of the scrollbar thumb */
	border-radius: 4px;
	/* Rounded corners for the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background: #07357b;
	/* Color of the scrollbar thumb on hover */
}

.tooltiptext {
	visibility: hidden;
	width: 100%;
	background-color: #000;
	color: #fff;
	text-align: center;
	border-radius: 1px;
	padding: 5px 0;
	font-size: 12px;
	position: absolute;
	top: 80%;
	left: 20%;
	margin-left: -60px;
}

.tooltipdeera:hover {
	.tooltiptext {
		visibility: visible;

	}


}

.padding_section {

	padding-left: 32px;
}


.no-products-container {
	top: 50%;
	left: 50%;
	// transform: translate(-50%, -50%);
	text-align: center;
	padding: 30px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #f9f9f9;
	width: 60%;
	max-width: 510px;
	z-index: 9999;
	animation: slideIn 0.5s forwards;
	opacity: 0;
	margin-left: 52%;
	margin-top: 16%;
}

.no-products-container img {
	width: 50px;
	height: auto;
	margin-bottom: 10px;
}

.no-products-container h3 {
	font-size: 1.5em;
	color: #333;
	margin-bottom: 10px;
}

.no-products-container p {
	color: #666;
	margin-bottom: 20px;
}

.no-products-button {
	padding: 8px 16px;
	background-color: #c1c3c4;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	margin-top: 5%;
}

.no-products-button:hover {
	background-color: #d6d9dc;
}


.deera-container.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.disabled img {
	opacity: 0.5;
	cursor: not-allowed !important;
}

@keyframes slideIn {
	from {
		transform: translate(-50%, -100%);
		opacity: 0;
	}

	to {
		transform: translate(-50%, -50%);
		opacity: 1;
	}
}

/// Sample Menu
body {
	background: #EEE;
	overflow-x: hidden;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	*zoom: 1;
}

.container {
	position: relative;
	margin: 0px auto;
	padding: 10px 0;
	clear: both;
}

@media only screen and (min-width: 1200px) {
	.container {
		width: 1210px;
	}
}

@media only screen and (min-width: 960px) and (max-width: 1199px) {
	.container {
		width: 1030px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
	.container {
		width: 682px;
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.container {
		width: 428px;
		margin: 0 auto;
	}
}

@media only screen and (max-width: 479px) {
	.container {
		width: 320px;
		margin: 0 auto;
	}
}



.mcd-menu {
	list-style: none;
	padding: 0;
	margin: 0;
	background: #FFF;
	/*height: 100px;*/
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;

	/* == */
	width: 250px;
	/* == */
}

.mcd-menu li {
	position: relative;
	/*float:left;*/
}

.mcd-menu li a {
	display: block;
	text-decoration: none;
	padding: 12px 20px;
	color: #777;
	/*text-align: center;
  border-right: 1px solid #E7E7E7;*/

	/* == */
	text-align: left;
	height: 36px;
	position: relative;
	//   border-bottom: 1px solid #EEE;
	/* == */
}

.mcd-menu li a i {
	/*display: block;
  font-size: 30px;
  margin-bottom: 10px;*/

	/* == */
	float: left;
	font-size: 20px;
	margin: 0 10px 0 0;
	/* == */

}

/* == */
.mcd-menu li a p {
	float: left;
	margin: 0;
}

/* == */

.mcd-menu li a strong {
	display: block;
	text-transform: uppercase;
}

.mcd-menu li a small {
	display: block;
	font-size: 10px;
}

.mcd-menu li a i,
.mcd-menu li a strong,
.mcd-menu li a small {
	position: relative;

	transition: all 300ms linear;
	-o-transition: all 300ms linear;
	-ms-transition: all 300ms linear;
	-moz-transition: all 300ms linear;
	-webkit-transition: all 300ms linear;
}

.mcd-menu li:hover>a i {
	opacity: 1;
	-webkit-animation: moveFromTop 300ms ease-in-out;
	-moz-animation: moveFromTop 300ms ease-in-out;
	-ms-animation: moveFromTop 300ms ease-in-out;
	-o-animation: moveFromTop 300ms ease-in-out;
	animation: moveFromTop 300ms ease-in-out;
}

.mcd-menu li:hover a strong {
	opacity: 1;
	-webkit-animation: moveFromLeft 300ms ease-in-out;
	-moz-animation: moveFromLeft 300ms ease-in-out;
	-ms-animation: moveFromLeft 300ms ease-in-out;
	-o-animation: moveFromLeft 300ms ease-in-out;
	animation: moveFromLeft 300ms ease-in-out;
}

.mcd-menu li:hover a small {
	opacity: 1;
	-webkit-animation: moveFromRight 300ms ease-in-out;
	-moz-animation: moveFromRight 300ms ease-in-out;
	-ms-animation: moveFromRight 300ms ease-in-out;
	-o-animation: moveFromRight 300ms ease-in-out;
	animation: moveFromRight 300ms ease-in-out;
}

.mcd-menu li:hover>a {
	color: #103672;
}

.mcd-menu li a.active {
	position: relative;
	color: #103672;
	border: 0;
	/*border-top: 4px solid #e67e22;
  border-bottom: 4px solid #e67e22;
  margin-top: -4px;*/
	//   box-shadow: 0 0 5px #DDD;
	//   -moz-box-shadow: 0 0 5px #DDD;
	//   -webkit-box-shadow: 0 0 5px #DDD;

	/* == */
	//   border-left: 4px solid #e67e22;
	//   border-right: 4px solid #e67e22;
	margin: 0 -4px;
	/* == */
}

.mcd-menu li a.active:before {
	content: "";
	position: absolute;
	/*top: 0;
  left: 45%;
  border-top: 5px solid #e67e22;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;*/

	/* == */
	top: 42%;
	left: 0;
	//   border-left: 5px solid #e67e22;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	/* == */
}

/* == */
.mcd-menu li a.active:after {
	content: "";
	position: absolute;
	top: 42%;
	right: 0;
	//   border-right: 5px solid #e67e22;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
}

/* == */

@-webkit-keyframes moveFromTop {
	from {
		opacity: 0;
		-webkit-transform: translateY(200%);
		-moz-transform: translateY(200%);
		-ms-transform: translateY(200%);
		-o-transform: translateY(200%);
		transform: translateY(200%);
	}

	to {
		opacity: 1;
		-webkit-transform: translateY(0%);
		-moz-transform: translateY(0%);
		-ms-transform: translateY(0%);
		-o-transform: translateY(0%);
		transform: translateY(0%);
	}
}

@-webkit-keyframes moveFromLeft {
	from {
		opacity: 0;
		-webkit-transform: translateX(200%);
		-moz-transform: translateX(200%);
		-ms-transform: translateX(200%);
		-o-transform: translateX(200%);
		transform: translateX(200%);
	}

	to {
		opacity: 1;
		-webkit-transform: translateX(0%);
		-moz-transform: translateX(0%);
		-ms-transform: translateX(0%);
		-o-transform: translateX(0%);
		transform: translateX(0%);
	}
}

@-webkit-keyframes moveFromRight {
	from {
		opacity: 0;
		-webkit-transform: translateX(-200%);
		-moz-transform: translateX(-200%);
		-ms-transform: translateX(-200%);
		-o-transform: translateX(-200%);
		transform: translateX(-200%);
	}

	to {
		opacity: 1;
		-webkit-transform: translateX(0%);
		-moz-transform: translateX(0%);
		-ms-transform: translateX(0%);
		-o-transform: translateX(0%);
		transform: translateX(0%);
	}
}



.mcd-menu li ul,
.mcd-menu li ul li ul {
	position: absolute;
	height: auto;
	min-width: 200px;
	padding: 0;
	margin: 0;
	background: #FFF;
	/*border-top: 4px solid #e67e22;*/
	opacity: 0;
	visibility: hidden;
	transition: all 300ms linear;
	-o-transition: all 300ms linear;
	-ms-transition: all 300ms linear;
	-moz-transition: all 300ms linear;
	-webkit-transition: all 300ms linear;
	/*top: 130px;*/
	z-index: 1000;

	/* == */
	left: 280px;
	top: 0px;
	border-left: 4px solid #103672;
	/* == */
}

.mcd-menu li ul:before {
	content: "";
	position: absolute;
	/*top: -8px;
  left: 23%;
  border-bottom: 5px solid #e67e22;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;*/

	/* == */
	top: 25px;
	left: -9px;
	//   border-right: 5px solid #103672;
	border-left: 5px solid #103672;
	border-bottom: 5px solid transparent;
	border-top: 5px solid transparent;
	/* == */
}

.mcd-menu li:hover>ul,
.mcd-menu li ul li:hover>ul {
	display: block;
	opacity: 1;
	visibility: visible;
	/*top: 100px;*/

	/* == */
	left: 250px;
	/* == */
}

/*.mcd-menu li ul li {
  float: none;
}*/
.mcd-menu li ul li a {
	padding: 10px;
	text-align: left;
	border: 0;
	border-bottom: 1px solid #EEE;

	/* == */
	height: auto;
	/* == */
}

.mcd-menu li ul li a i {
	font-size: 16px;
	display: inline-block;
	margin: 0 10px 0 0;
}

.mcd-menu li ul li ul {
	left: 230px;
	top: 0;
	border: 0;
	border-left: 4px solid #103672;
}

.mcd-menu li ul li ul:before {
	content: "";
	position: absolute;
	top: 205px;
	/*left: -14px;*/
	/* == */
	left: -9px;
	/* == */
	border-right: 5px solid #103672;
	border-bottom: 5px solid transparent;
	border-top: 5px solid transparent;
}

.mcd-menu li ul li:hover>ul {
	top: -195px;
	left: 200px;
}



/*.mcd-menu li.float {
  float: right;
}*/
.mcd-menu li a.search {
	/*padding: 29px 20px 30px 10px;*/
	padding: 10px 10px 15px 10px;
	clear: both;
}

.mcd-menu li a.search i {
	margin: 0;
	display: inline-block;
	font-size: 18px;
}

.mcd-menu li a.search input {
	border: 1px solid #EEE;
	padding: 10px;
	background: #FFF;
	outline: none;
	color: #777;

	/* == */
	width: 170px;
	float: left;
	/* == */
}

.mcd-menu li a.search button {
	border: 1px solid #103672;
	/*padding: 10px;*/
	background: #103672;
	outline: none;
	color: #FFF;
	margin-left: -4px;

	/* == */
	float: left;
	padding: 10px 10px 11px 10px;
	/* == */
}

.mcd-menu li a.search input:focus {
	border: 1px solid #103672;
}


.search-mobile {
	display: none !important;
	background: #103672;
	border-left: 1px solid #103672;
	border-radius: 0 3px 3px 0;
}

.search-mobile i {
	color: #FFF;
	margin: 0 !important;
}


@media only screen and (min-width: 960px) and (max-width: 1199px) {
	.mcd-menu {
		margin-left: 10px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
	.mcd-menu {
		width: 200px;
	}

	.mcd-menu li a {
		height: 30px;
	}

	.mcd-menu li a i {
		font-size: 22px;
	}

	.mcd-menu li a strong {
		font-size: 12px;
	}

	.mcd-menu li a small {
		font-size: 10px;
	}

	.mcd-menu li a.search input {
		width: 120px;
		font-size: 12px;
	}

	.mcd-menu li a.search buton {
		padding: 8px 10px 9px 10px;
	}

	.mcd-menu li>ul {
		min-width: 180px;
	}

	.mcd-menu li:hover>ul {
		min-width: 180px;
		left: 200px;
	}

	.mcd-menu li ul li>ul,
	.mcd-menu li ul li ul li>ul {
		min-width: 150px;
	}

	.mcd-menu li ul li:hover>ul {
		left: 180px;
		min-width: 150px;
	}

	.mcd-menu li ul li ul li:hover>ul {
		left: 150px;
		min-width: 150px;
	}

	.mcd-menu li ul a {
		font-size: 12px;
	}

	.mcd-menu li ul a i {
		font-size: 14px;
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {

	.mcd-menu {
		width: 50px;
	}

	.mcd-menu li a {
		position: relative;
		padding: 12px 16px;
		height: 20px;
	}

	.mcd-menu li a small {
		display: none;
	}

	.mcd-menu li a strong {
		display: none;
	}

	.mcd-menu li a:hover strong,
	.mcd-menu li a.active strong {
		display: block;
		font-size: 10px;
		padding: 3px 0;
		position: absolute;
		bottom: 0px;
		left: 0;
		background: #103672;
		color: #FFF;
		min-width: 100%;
		text-transform: lowercase;
		font-weight: normal;
		text-align: center;
	}

	.mcd-menu li .search {
		display: none;
	}

	.mcd-menu li>ul {
		min-width: 180px;
		left: 70px;
	}

	.mcd-menu li:hover>ul {
		min-width: 180px;
		left: 50px;
	}

	.mcd-menu li ul li>ul,
	.mcd-menu li ul li ul li>ul {
		min-width: 150px;
	}

	.mcd-menu li ul li:hover>ul {
		left: 180px;
		min-width: 150px;
	}

	.mcd-menu li ul li ul li>ul {
		left: 35px;
		top: 45px;
		border: 0;
		border-top: 4px solid #103672;
	}

	.mcd-menu li ul li ul li>ul:before {
		left: 30px;
		top: -9px;
		border: 0;
		border-bottom: 5px solid #103672;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
	}

	.mcd-menu li ul li ul li:hover>ul {
		left: 30px;
		min-width: 150px;
		top: 35px;
	}

	.mcd-menu li ul a {
		font-size: 12px;
	}

	.mcd-menu li ul a i {
		font-size: 14px;
	}

}

@media only screen and (max-width: 479px) {
	.mcd-menu {
		width: 50px;
	}

	.mcd-menu li a {
		position: relative;
		padding: 12px 16px;
		height: 20px;
	}

	.mcd-menu li a small {
		display: none;
	}

	.mcd-menu li a strong {
		display: none;
	}

	.mcd-menu li a:hover strong,
	.mcd-menu li a.active strong {
		display: block;
		font-size: 10px;
		padding: 3px 0;
		position: absolute;
		bottom: 0px;
		left: 0;
		background: #103672;
		color: #FFF;
		min-width: 100%;
		text-transform: lowercase;
		font-weight: normal;
		text-align: center;
	}

	.mcd-menu li .search {
		display: none;
	}

	.mcd-menu li>ul {
		min-width: 180px;
		left: 70px;
	}

	.mcd-menu li:hover>ul {
		min-width: 145px;
		left: 50px;
	}

	.mcd-menu li ul li>ul,
	.mcd-menu li ul li ul li>ul {
		min-width: 150px;
	}

	.mcd-menu li ul li:hover>ul {
		left: 130px;
		min-width: 120px;
	}

	.mcd-menu li ul li ul li>ul {
		left: 35px;
		top: 45px;
		border: 0;
		border-top: 4px solid #103672;
	}

	.mcd-menu li ul li ul li>ul:before {
		left: 30px;
		top: -9px;
		border: 0;
		border-bottom: 5px solid #103672;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
	}

	.mcd-menu li ul li ul li:hover>ul {
		left: 30px;
		min-width: 150px;
		top: 35px;
	}

	.mcd-menu li ul a {
		font-size: 12px;
	}

	.mcd-menu li ul a i {
		font-size: 14px;
	}

}


.dropdown {
	position: relative;
	display: inline-block;
}

.dropbtn {
	background-color: #3498db;
	color: white;
	padding: 16px;
	font-size: 16px;
	border: none;
	cursor: pointer;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 50;
}

.dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown-content a:hover {
	// background-color: #f1f1f1;
	background-color: #c3c9e6;
}

.dropdown-submenu {
	position: relative;
}

.submenu-content {
	display: none;
	position: absolute;
	left: 100%;
	top: 0px;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.submenu-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.submenu-content a:hover {
	// background-color: #f1f1f1;
	background-color: #c3c9e6;
}

.subsubmenu-content {
	display: none;
	position: absolute;
	left: 100%;
	top: -50px;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.subsubmenu-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.subsubmenu-content a:hover {
	// background-color: #f1f1f1;
	background-color: #c3c9e6;
}

.show {
	display: block;
}

.align-left-com {
	margin-left: -1%;
}

.privacy-card-align {
	padding: 30px;
	padding-left: 18%;


}