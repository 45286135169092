.earthy-product-title {
	display: -webkit-box !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	text-overflow: ellipsis !important;
	background-clip: text;
	-webkit-background-clip: text;
	overflow: hidden !important;
}

.checkout-forms .input_top {
	margin-top: 0px !important;
	font-size: 14px !important;
	outline: none !important;
	margin-left: 0px !important
}

.sizebox {
	padding: .374rem 0px !important;
	border-style: none !important;
	background-color: #d3d3d3 !important;
	color: #000 !important;
	margin: 3px;
	border-radius: 25px;
}

.DeeraviewCart {
	width: 50%;
}

.simple_add_deera {
	width: 65% !important;
}

@media (min-width: 1200px) {
	.container {
		max-width: 93% !important;
	}
}

@media (min-width: 1300px) {
	.container {
		max-width: 1280px;
	}
}

/*@media (min-width: 992px) {
	  .navbar-toggler {
		  display: none;
	  }
	  .btn.close-btn {
		  display: none;
	  }
	  
  }*/
@media (min-width: 991px) {
	.trers-grid-image {
		img {
			max-width: 150px;
		}
	}
}

.earthy-card:hover {
	min-height: 340px !important;
	max-height: 340px !important;
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

	.earthy-image {
		min-height: 180px !important;
		max-height: 180px !important;
		padding: 10px;
	}

	.earthy-description {
		box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
		min-height: 160px !important;
		max-height: 160px !important;
	}

	.earthy-product-title {
		margin-top: 5px;
		font-size: 14px;
	}

	.earthy-cart-btn {
		padding-top: 10px;
		display: block;
	}

	.earthy-btn {
		border-radius: 6px !important;
		padding: 5px 20px !important;
		background-color: #BDC62B !important;
		border: none !important;
		border-radius: 20px !important;
		font-size: 13px !important;
		font-weight: 500 !important;
	}

	.karupattiraja-btn {
		margin-top: 0px !important;
		border-radius: 6px !important;
		padding: 5px 20px !important;
		background-color: #212529 !important;
		border: none !important;
		border-radius: 20px !important;
		font-size: 13px !important;
		font-weight: 500 !important;
	}
}

@media (min-width: 640px) {



	.deera_link {
		color: #1e4edc;
	}

	.deera_link:hover {
		text-decoration: underline;
		color: orange;
		cursor: pointer;
	}

	.deera-strike {
		margin-top: 2px;
	}

	.deercar {
		width: 32%;
	}

	.cartaddfun {
		display: flex;
	}

	.sizebutton {
		width: 75px;
	}

	.deera-listrow {
		margin-right: 0px !important;
		margin-left: 0px !important;
	}

	.avon-chart {
		width: -webkit-fill-available;

	}

	.sizeText {
		font-size: 14px;
		font-weight: 800;
		color: black;
	}

	.product-list-cards-deera-promo {
		height: 30px;
		border: none;
		border-radius: 10px;
		min-width: 300px;
		max-width: 300px;
	}

	.deera-price-row {
		margin-top: 8px;
	}

	.deera-price-row1 {
		margin-top: 8px;
		margin-left: 10px;
	}

	.deera-featured-products {
		margin-top: 35px;
	}
}

@media (max-width: 640px) {
	.DeeraviewCart {
		width: 65% !important;
	}

	.cartButton {
		width: 44% !important;
	}

	.deera_map_space {
		margin-top: 2%;
	}

	.deera-featured-products {
		margin-top: 50px;
	}

	.rounder-corner-nav .owl-theme .owl-nav .owl-next {
		margin-left: 82% !important;
	}

	.rounder-corner-nav .owl-theme .owl-nav {
		transform: translateY(-12px) !important;
	}

	.deera_review_content {
		margin-left: 15px;
		text-align: justify;
		margin-right: 12px;
	}

	.deera-price-row1 {
		margin-top: 7px;
		margin-left: 10px;
	}

	.deera-price-row {
		margin-top: 7px;
	}

	.deercar {
		width: 45%;
	}

	.sizebox {
		padding: .374rem 0px !important;
		border-style: none !important;
		background-color: #d3d3d3 !important;
		color: #000 !important;
		margin: 3px;
		width: 50px;
		border-radius: 25px;
	}

	.avon-chart {
		width: 100%;

	}

	.cartaddfun {
		display: flex;
	}

	.no-products-container {
		top: 50%;
		left: 50%;
		text-align: center;
		padding: 30px;
		border: 1px solid #ccc;
		border-radius: 5px;
		background-color: #f9f9f9;
		width: 85% !important;
		max-width: 539px;
		z-index: 9999;
		animation: slideIn .5s forwards;
		opacity: 0;
		margin-left: 50% !important;
		margin-top: 55% !important;
	}
}

@media (max-width: 1199px) {
	footer {
		.footer-links {
			display: block;
		}
	}

	.free-shipping-section {
		ul {
			flex-flow: wrap;

			li {
				margin-bottom: 15px;
			}
		}
	}

	// body {
	//   padding-top: 47px;
	// }

	.header {
		.flex-container {
			.header-row-one {
				display: block;

				.account-section {
					position: absolute;
					top: 0;
					width: 100%;
					border-bottom: 1px solid #00000012;
					left: 0;
					z-index: 99;
					background: #fff;

					.account {
						justify-content: flex-end;
						margin-top: 10px;
					}
				}
			}
		}
	}
}

@media (max-width: 991px) {

	.product-details-wrapper {
		.slider-nav {
			bottom: 6px;

		}

		.details-wrapper {

			.product-all-details {
				.row {
					.col-left {
						flex: auto;
					}
				}

				.product-addtional-details {
					padding-bottom: 15px;

					&:before {
						width: 100%;
					}
				}
			}
		}
	}

	.banner-content {
		.content {

			h2,
			h1 {
				font-size: 30px;
			}
		}
	}

	/**Product**/
	.product-box-inner {
		.product-image {
			max-width: 150px;
		}

		.product-detail {
			.price-wrapper {
				position: inherit;
				transform: none;
			}

			.add-to {
				position: inherit;
			}
		}

		.verified {
			top: 10px;
		}
	}

	.best-products {
		.row-fluid {
			display: block;

			.tyers-grid {
				justify-content: space-between;

				li {
					display: inline-block;
					margin: 15px 0px 15px 0px;
					text-align: center;
				}
			}
		}

		.column {
			&.single-image {
				margin-top: 15px;
			}
		}
	}
}

@media (max-width: 767px) {
	.mob-filter {

		z-index: 200;
		background-color: $bg-color;
		width: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		position: fixed;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.filter-button {
		display: block !important;
		position: fixed;
		bottom: 4%;
		left: 45%;
		width: 50%;
		height: 8%;
		border-radius: 50px;
		border: 2px solid $primary;
		font-size: 18px;
		font-weight: 600;
		-webkit-box-shadow: 0px 0px 33px -10px rgba(0, 0, 0, 0.59);
		-moz-box-shadow: 0px 0px 33px -10px rgba(0, 0, 0, 0.59);
		box-shadow: 0px 0px 33px -10px rgba(0, 0, 0, 0.59);
	}


	.details-wrapper {
		padding-right: 0px !important;
		padding-left: 0px !important;
		margin-right: 0px !important;
		margin-left: 0px !important;

		.slider-nav {
			bottom: 6px;
			max-width: 240px;
			min-width: 230px;
		}
	}

	.banner-content {
		.content {

			h2,
			h1 {
				font-size: 20px;
			}
		}
	}

	.aside-group {
		display: block;
	}

	/**Title**/
	.content-title {
		.title-box {
			.title {
				&:after {
					display: none;
				}
			}
		}
	}

	.header {
		.flex-container {
			display: block;

			.logo {
				text-align: center;
				padding: 15px;
				margin: 0px auto;
			}

			.header-row-one {
				.search-bar-group {
					.search-bar {
						margin-top: 10px;
						margin-right: -4rem;
						width: 100% !important;

					}
				}
			}
		}
	}

	.content-1-section {
		position: static;
		border-radius: 0px;
	}

	.homepage-banner-slider {
		.owl-theme {
			.owl-dots {
				bottom: 0;
			}
		}
	}

	.best-products {
		.column {
			.tyers-grid {
				li {
					display: block;
					text-align: center;
				}
			}
		}
	}
}

.deera-logo-images {
	width: 100px;
	cursor: pointer;
	// @include media-breakpoint-down(sm){
	//     width: 165px;
	//     height:40px;
	// }
}

@media (min-width: 640px) {
	.pad-top {
		width: 37%;
		margin-top: 4px;
	}

	.deera_cartbtn_height {
		height: 45px;
	}

	.deer_quant {
		padding: .480rem 0rem !important;
		margin-top: 3px;
	}
}

@media (max-width: 640px) {
	.product-list-cards-deera_variable {
		min-width: 0px !important;
		max-width: 100% !important;
		min-height: 260px !important;
		max-height: 240px !important;
		padding: 0;
		width: 100%;
		display: flex;
		box-shadow: 0 0 10px 4px #00000040;
		margin-bottom: 10px;
	}

	.content-title {
		padding: 0px 0px 30px !important;
	}

	.checkout-btn {
		min-width: fit-content;
		padding-left: 0px !important;
		height: 30px !important;
		position: relative;
		top: 0px;
		width: 150px !important;
		margin-top: 16%;
	}

	.collection-wrapper {
		.col-grid-box {
			padding: 0px 10px 0px 10px;
		}
	}

	footer {
		.footer-links {
			display: grid !important;
		}

		.footer-col {
			justify-content: center !important;
			padding: 5px;
			margin: 5px;
			min-width: 136%;
		}
	}

	.banner-content {
		.content {

			h2,
			h1 {
				font-size: 18px;
			}
		}
	}

	.header {
		.flex-container {
			.header-row-one {
				.search-bar {
					margin-left: 0px;
				}

				.account-section {
					position: fixed !important;
					z-index: 2 !important;
					padding-right: 0px;

					.account {
						margin-right: 10px !important;
					}

					.dropdown-toggle {
						font-size: 10px !important;
					}

					.fa-user {
						font-size: 10px !important
					}
				}
			}
		}
	}


	.product-box-inner {
		display: block;
		padding: 10px 0px 10px 0;

		.img-wrapper {
			padding-bottom: 10px;

			.product-image {
				max-width: 100%;
				width: 100%;
			}
		}

		.product-detail {
			padding-left: 0px;
		}

		.verified {
			top: 10px;
			transform: none;
		}
	}

	/*Pagenation**/
	.product-pagination {
		.row {
			display: block;

			>div {
				margin-bottom: 10px;
				text-align: center;

				p {
					margin-bottom: 0px;
				}

				.pagination {
					justify-content: center;
				}
			}
		}
	}

	.mobile-filter {
		display: block !important;
	}

	.desktop-filter {
		display: none !important;
	}

	.mob-categories {

		opacity: 1;
		padding: 10px;
		font-family: 'proxima-regular', 'sans-serif' !important;
		position: fixed;
		background-color: white;
		z-index: 100;
		width: 100%;
		height: 100%;
		border-right: 50px solid rgba(0, 0, 0, 0.5);
		overflow-y: auto;
		top: 0;
		left: 0;
		background-clip: padding-box;


	}

	.touch-out {
		background-color: transparent;
		position: fixed;
		z-index: 999;
		width: 60px;
		right: 0px;
		height: 300%;
		top: 0px;
		overflow: hidden;

	}

	.deera-logo-images {
		width: 85px;
		height: 40px;
	}

	// body{
	// 	position: fixed !important;
	// }

}

/*** Range **/

@media (max-width: 991px) and (min-width: 640px) {
	.product-name {
		font-size: 18px;
	}

	.price {
		font-size: 18px;
	}

	.product-price {
		.tax-includes {
			font-size: 13px;
		}
	}

	.verified {
		font-size: 13px;
	}

	.add-to {
		.btn-solid {
			font-size: 15px;
		}
	}
}


@media only screen and (min-device-width: 720px) and (max-device-width: 1300px) {
	.deera-summary {
		display: none !important;
	}

	.deera-summary1 {
		display: flex !important;
	}

	.deera-summary2 {
		display: none !important;
	}



}


@media only screen and (min-device-width: 1350px) {
	.deera-summary {
		display: flex !important;
	}

	.deera-summary1 {
		display: none !important;
	}

	.deera-summary2 {
		display: none !important;
	}

}


@media screen and (max-width: 600px) {
	.responsive-align {
		margin-top: -6%;
	}

	.privacy-card-align {
		padding-left: 7% !important;
		padding: 30px;

	}

	.deera-summary {
		display: none !important;
	}

	.deera-summary1 {
		display: none !important;
	}

	.deera-summary2 {
		display: flex !important;
	}

	.deera-chart {
		width: 100%;

	}
}

.product-list-cards-deera_variable {
	box-shadow: 0 2px 14px #0003, 0 4px 14px #00000030;
	border-radius: 10px;

}